import React from 'react'
import './experience.css'
import {BsPatchCheckFill} from "react-icons/bs";


const Experience = () => {
    return (
        <section id='experience'>
            <h5>What Skills I have</h5>
            <h2>My Experience</h2>
            <div className="container experience__container">
                <div className="experience__frontend">
                    <h3>Frontend Development</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>HTML, CSS3, Sass(SCSS)</h4>
                                <small className="text-light">Expert</small>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>JAVASCRIPT, Typescript, Jquery</h4>
                                <small className="text-light">Experienced</small>
                            </div>

                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>Adobe Photoshop, Figma</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>Gulp, Vite, Webpack</h4>
                                <small className="text-light">Experienced</small>
                            </div>

                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>Wordpress</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>Angular</h4>
                                <small className="text-light">Experienced</small>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>Vue</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                    </div>
                </div>
                <div className="experience__backend">
                    <h3>Backend Development</h3>
                    <div className="experience__content">
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>PHP</h4>
                                <small className="text-light">Intermediate</small>
                            </div>
                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>MySQL</h4>
                                <small className="text-light">Basic</small>
                            </div>

                        </article>
                        <article className="experience__details">
                            <BsPatchCheckFill/>
                            <div>
                                <h4>Laravel</h4>
                                <small className="text-light">Basic</small>
                            </div>
                        </article>
                    </div>
                </div>
            </div>
            <div className="experience__backend">

            </div>


        </section>
    )
}
export default Experience