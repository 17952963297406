import React from 'react'
import './contact.css'

const Contact = () => {
    return (
        <section id='contact'>
            <h5>Get In Touch</h5>
            <h2> Contact Me </h2>


            <div className="container contact__container">
                <div className="contact__option">
                    <h4>Email</h4>
                    <h5>
                        <a href="mailto:kalinka03333@gmail.com">    kalinka03333@gmail.com</a>
                    </h5>
                </div>
                <div className="contact__option">
                    <h4>Telegram</h4>
                    <h5><a href="https://t.me/kalinka_katya">kalinka_katya</a></h5>
                </div>
            </div>
            {/*<form action="">*/}
            {/*    <input type="text" name="name" placeholder="Your Full Name" required=""/>*/}
            {/*    <input type="email" name="email" placeholder="Your Email" required=""/>*/}
            {/*    <textarea name="message" rows="7" placeholder="Your Message" required=""> </textarea>*/}
            {/*    <button type="submit" className="btn btn-primary">Send Message</button>*/}
            {/*</form>*/}
        </section>
    )
}
export default Contact