import React, {useState} from 'react'
import './about.css'
import MyPhoto from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {VscFolderLibrary} from 'react-icons/vsc'
import {BrowserRouter as Router, Route, Link, Switch} from 'react-router-dom';

const About = () => {
    return (
        <section id='about'>
            <h5>Get To Know</h5>
            <h2>About Me</h2>

            <div className="container about__container">
                <div className="about__me">
                    <div className="about__me-image">
                        <img src={MyPhoto} alt=""/>
                    </div>
                </div>
                <div className="about__content">
                    <div className="about__cards">
                        <article className="about__card">
                            <FaAward className="about__icon"/>
                            <h5>Experience</h5>
                            <small>6+ Years Working</small>
                        </article>
                        <article className="about__card">
                            <VscFolderLibrary className="about__icon"/>
                            <h5>Projects</h5>
                            <small>20+ Completed</small>
                        </article>
                    </div>
                    <p>"Greetings! I am an experienced frontend developer with over 5 years of professional
                        experience. My career journey began on a freelance platform, and currently, I hold the
                        position of front-end developer at Art-sites.
                    </p>
                    <p>
                        My responsibilities include: <br/>
                        Website layout according to the design layout using Pixel Perfect.
                        Development of responsive websites of varying complexity based on approved design layouts.
                        Integration of layouts into the WordPress platform, involving customization of fields and
                        comprehensive website editing through the administrative panel.
                        Collaboration and coordination with the design department.
                        Project development using Vue.js.
                        Working on performance aspects, conducting code optimization and refactoring.
                        Editing and providing support for ongoing projects.
                    </p>
                    <p>
                        I excel in teamwork, constantly seeking innovative approaches to problem-solving. I am
                        proficient in the English language, capable of reading documentation and writing
                        comments."</p>
                    <a href="#contacts" className='btn btn-primary'>Let's Talk</a>
                </div>
            </div>
        </section>
    )
}
export default About