import React from 'react'
import Header from '../../components/header/Header'
import Contact from '../../components/contact/Contact'
import  Nav from '../../components/nav/Nav'
import About from "../../components/about/About";
import Experience from "../../components/experience/Experience";
import Footer from "../../components/footer/Footer";
import Portfolio from "../../components/portfolio/Portfolio";




const Home = () => {
    return (
        <>
            <Header/>
            <Nav/>
            <About/>
            <Experience/>
            <Portfolio/>
            <Contact/>
            <Footer/>

        </>
    )
}
export {Home}