import React from 'react';
import {Routes, Route, Link} from 'react-router-dom';
import { Home } from  './components/Home/Home';
import { Project } from  './components/Project/Project';

function App() {
    return (
        <>
            <div>
                {/*<Link to="/">Home</Link>*/}
                {/*<Link to="/project">Project</Link>*/}

            </div>
            <Routes>
                <Route path="/" element={<Home />} />
                {/*<Route path="/project" element={<Project />} />*/}

            </Routes>
        </>
    );
}

export default App;